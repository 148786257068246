/* @import url('https://fonts.googleapis.com/css?family=Montserrat:wght@400;500;600;700;800;900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
:root{
  --dark-color: #222834;
  --light-color: #eee;
  --button1-color: #FD7014;
  --button2-color: #393E46;
}

body {
  list-style-type: none;
  text-align: center;
  background-color: var(--dark-color);
  color: var(--light-color);
  font-family:  'Inter',sans-serif;
  background-image: url("data:image/svg+xml,%3Csvg width='42' height='44' viewBox='0 0 42 44' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Page-1' fill='none' fill-rule='evenodd'%3E%3Cg id='brick-wall' fill='%23525252' fill-opacity='0.4'%3E%3Cpath d='M0 0h42v44H0V0zm1 1h40v20H1V1zM0 23h20v20H0V23zm22 0h20v20H22V23z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

h1 {
  font-size: 36px;
  font-weight:800 ;
}

h2 {
  color:var(--light-color);
  font-size: 32px;
  font-weight: 800;
}

h3 {
  font-size: 24px;
  font-weight: 800;
}

.App {
  text-align: center;
}

.Header {
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: 0px 4px 10px 5px hsla(0, 0%, 0%, 0.3);
}

.About, .Skills, .ProjectsContainer, .Contact {
  padding-top: 20px;
  padding-bottom: 20px;
}

.About {
  text-align: center;
  margin:auto;
  padding-top: 20px;
  padding-bottom: 0px;
}

.ProjectsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 350px));
  justify-content: center;
  margin: auto;
  align-items: center;
  gap: 24px;
}

a {
  color: #eee;
}

.ProjectCard img {
  width: 225px;
  height: auto;
}

.ProjectCard {
  width: 300px;
  height: 345px;
  color: var(--button2-color);
  border-radius: 5px;
  padding: 5px;
  margin: auto;
  background-color: var(--light-color);
  color: var(--dark-color);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  box-shadow: 10px 10px 10px 0 hsla(0, 0%, 0%, 0.5);
  }

.ProjectCard P {
  font-weight: 300;
  font-size: 14px;
}

.ProjectCard:hover {
  outline: 4px solid var(--button1-color);
}

.ButtonContainer {
  margin-bottom: 10px;
  flex-direction: column;
  display: flex;
}


.ProjectButtons {
  border: none;
  padding: 6px;
  margin: 4px;
  border-radius: 2px;
  font-size: 14px;
  color: var(--button2-color);
  text-decoration: none;
}

.PreviewButton {
  background-color: var(--button1-color);
  outline: 2px solid var(--button1-color);

}

.PreviewButton:hover {
  outline: 2px solid var(--button2-color);
}

.CodeButton {
  background-color: var(--light-color);
  outline: 2px solid var(--button2-color);
}

.CodeButton:hover {
  background-color: rgb(201, 201, 201);
  
}

.ContactIcons {
  display: flex;
  flex-direction: row ;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color:#eee;
  height: 45px;
  width: auto;
  padding-bottom: 50px;
}

i {
  color:#eee;
}


@media (min-width: 600px) {
  .ProjectsContainer {
    width: 80%;
  }
  .About {
    width: 50%;
  }
}

@media (max-width: 700px) {
  .About {
    width: 75%;
  }
  .ProjectCard {
    padding: 0px;
  }
  .SkillIcons {
    width: 75%;
  }
  .Projects {
    width: 80%;
    margin: auto;
  }
}
